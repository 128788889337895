// -------------------------------------------------------------
//
//	Footer Component
//
//	------------------------------------------------------------

@import "../colours";
@import "../typography";
@import "../breakpoints";

.footer {
	position: fixed !important;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #1b2029;
	background: url('/assets/images/background-image_sml.jpg') center 30% no-repeat;
	background-size: cover;
	position: relative;
	padding: 6px;
	margin-bottom: -10px;
	z-index: 10;
	p {
		line-height: 13px;
		font-size: 10px;
		margin-bottom: 0;
		text-indent: 0;
		@media screen and (min-width: 600px) {
			line-height: 26px;
			font-size: 12px;
		}
		@media screen and (min-width: 900px) {
			font-size: 14px;
		}
	}

	.form__error {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
	}

	&--bottom-section {
		&-image_container {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}
		background-color: rgba(0,0,0,0.4);
		width: 100%;
		min-height: 100px;
		padding: 10px;
		line-height: 90px !important;
		box-sizing: border-box;
		text-indent: 14px;
		color: #9e9e9e;
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 600px) {
			flex-direction: row-reverse;
			justify-content: space-between;
			align-items: center;
			gap: 20px;
		}

		> * {
			font-size: 90%;
			@include respond-to('large-up') {
				font-size: 100%;
			}
		}

		.notsm {
			display: none;

			@include respond-to('medium-up') {
				display: inline-block;
				margin-right: 5px;
			}
		}

	}

	&__title {
		text-align: center;
        display: block;
        width: 100%;
        font-size: 20px;
        margin-bottom: 0px;
        font-weight: 200;
        margin-top: 0;
        color: #aec8d5;
	}

	&__container {
		padding: 24px;
	}

	&__credit {
		display: inline-block;
		width: 48%;
	}

	&__copyright {
		display: inline-block;
		width: 48%;
		text-align: right;
	}

	.button {
		width: 100%;
	}
}

.social {
	&__link {
		height: 70px;
		float: right;
	    display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		 img {
			 height: 40px;
		 }
	}
}

.subscribe {
	width: 100%;
	margin-top: 32px !important;
	margin-bottom: 110px !important;
	max-width: 840px;

	@include respond-to('large-up') {
		width: 60%;
	}
}
