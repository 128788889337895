// -------------------------------------------------------------
//
//	Button styles
//
//	------------------------------------------------------------
//

@import "breakpoints";


.mdl-button {

    &.button {
    	font-family: $font-heading;
      background: $c-primary;
      transition: ease .2s all;

        &:hover {
            background: $c-primary;
            filter: brightness(1.1);
            -webkit-filter: brightness(1.1);
            transition: ease .2s all;
        }

        &:active {
            background: $c-primary;
            filter: brightness(1.1);
            -webkit-filter: brightness(1.1);
            transition: ease .2s all;
        }

        &:focus {
            background: $c-primary !important;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                        0 3px 1px -2px rgba(0, 0, 0, 0.2),
                        0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        }

        @include respond-to('large-up') {
            //min-width: 100%;
        }
    }

    &.material-icons {
        margin-top: -2px;
    }

    &.fullwidth__button {

        @include respond-to('large-up') {
            min-width: 100%;
        }

    }
}
