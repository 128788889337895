// -------------------------------------------------------------
//
//	Typographic styles
//
//	------------------------------------------------------------

@import "colours";

$font-heading: 'Brandon Grotesque Bold', Helvetica, "Helvetica Neue", Arial, Tahoma, Verdana, "sans-serif";
$font-copy: 'Brandon Grotesque Bold', Helvetica, "Helvetica Neue", Arial, Tahoma, Verdana, "sans-serif";

//
// Colour variables.
//
$colour-main-heading: $c-primary;
$colour-main-copy: $c-primary-font;

%heading {
	font-family: $font-heading;
	color: $colour-main-heading;
}

%main-heading {
	@extend %heading;
	font-size: 32px;
	border-bottom: 1px solid #888;
	padding-bottom: 6px;
	margin-bottom: 24px;
}

%copy {
	font-family: $font-copy;
	color: $colour-main-copy;
}

a {
	text-decoration: none;
	color: inherit;
}

h1 {
	font-family: $font-heading !important;
}

h3 {
	margin: 0;
	font-size: 140%;
}

h4 {
	font-size: 130%;
}

h5 {
	font-size: 100%;
	font-family: $font-heading;
}


.ml-0 {
    margin-left: 0;
}