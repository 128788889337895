@import "./colours";
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.form {

	&__select {
		height: 36px !important;

		hr {
			display: none;
		}

		select {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			border-bottom: 0;
			text-indent: 10px;
			outline: none;
			display: block;
			width: 100%;
			padding: 4px 0;
			height: 100%;
			margin: 0;
			color: inherit;
			background: transparent;
			font-size: 16px;
			text-align: left;
			border: none;
			border-bottom: 1px solid rgba(0,0,0,.12);
			border-radius: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			-ms-appearance: none;
			appearance: none;
		}
	}

	&__selectfield {
		height: 36px !important;
		border-radius: 2px;
		background: $c-dull;
		margin-top: 0;
		text-align: left;
		padding: 0 !important;
		position: relative;

		i {
			position: absolute;
			right: 0;
			opacity: .3;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&__textfield {
		padding: 0 10px;
		background: #FFF;
		border-radius: 2px;
		width: 100%;
		color: #444;
	}

	&__textarea {
		height: 96px !important;
		padding: 10px !important;
		outline: none;
		background: $c-dull !important;
  	  	border-radius: 3px;
		box-sizing: border-box;
	}

	&__input {
	  height: 36px;
	  width: 100%;
	  box-sizing: border-box;
	  outline: none;
	  padding-right: 10px !important;
	  padding-left: 10px !important;
	  background: $c-dull !important;
	  border-radius: 3px;
	}

	&__label {
	  font-style: italic;
	  top: 8px !important;
	  text-indent: 10px;

	  &:after {
		  bottom: 0;
	  }
	}

	&__validation {
		background: transparent;
		color: $c-error;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: left;
		border: 0;

		small {
			display: inline-flex;
			// background: #F64662;
			// padding: 1px 6px;
			border-radius: 2px;
			position: relative;
			white-space: nowrap;
		}
	}

	&__success {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0;

		&--message {
			background: $c-success;
			color: #fff;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			padding: 14px 18px;
			font-size: 16px;
			margin: 14px 0;
			border-radius: 3px;

			i {
				margin-left: 8px;
			}
		}
	}

	.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
	    border: 2px solid #fff ;
	}

	.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
	    background-color: #FFF ;
	}

}

.has-error {
	.form-input {
		border-bottom: 1px solid $c-error;
	}
}

.form__error {
	margin-bottom: 16px;
	display: flex;
	justify-content: center;
	gap: 10px;

	span {
		background: #F64662;
		padding: 8px 14px;
		border-radius: 3px;
		position: relative;
		color: #fff;

		&:after {
            content: '';
            display: block;
            position: absolute;
            height: 0;
            width: 0;
            border-top: 6px solid #F64662;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: none;
            left: 50%;
            transform: translateX(-50%);
            bottom: -6px;
        }
	}
}

.bar {
    position: relative;
    display: block;
  }

  .bar:before,
  .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #566a8a;
    transition: 0.2s ease all;
  }

	.form__selectfield {
			width: 100% !important;
	}

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  input:focus {
    border-color: transparent;
  }

  input:focus ~ .bar:before,
  input:focus ~ .bar:after {
    width: 50%;
  }

  input::placeholder {
    color: rgba(0,0,0, 0.26);
    font-style: italic;
}

.contact__link {
		margin-left: 24px;
}

.mdl-textfield {
	padding: 0 !important;
	border-radius: 2px;
	width: 100% !important;
	color: #444;
}

textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	font-style: italic;
	font-weight: 100;
	opacity: .6;
}
textarea::-moz-placeholder { /* Firefox 19+ */
	font-style: italic;
	font-weight: 100;
	opacity: .6;
}
textarea:-ms-input-placeholder { /* IE 10+ */
	font-style: italic;
	font-weight: 100;
	opacity: .6;
}
textarea:-moz-placeholder { /* Firefox 18- */
	font-style: italic;
	font-weight: 100;
	opacity: .6;
}
