// -------------------------------------------------------------
//
//	Main Nav Component
//
//	------------------------------------------------------------
//

@import '../breakpoints';
@import '../typography';

.content-block {
		max-width: 1170px;
    	box-sizing: border-box;
		margin: auto;
		&-padded {
			padding-bottom: 150px;
		}
}

.app-drawer {
		z-index: 8888;
		position: fixed;
		top: 0;
}

.app__wrapper {
	overflow-y: auto !important;
	height: 100vh;

	.is-compact {

		.main_nav__item {
			margin-top: 0;
		}

		.main_nav__link {
			padding: 0 10px;
		}

		.main_nav__logo {
				width: 119px !important;
				transition: ease .2s all;
  	}
	}

	.header {
			background-color: #1b2029;
			background-image: url('/assets/images/background-image_sml.jpg');
			background-size: cover;
			background-position: center 10%;
			height: 100px;
			display: flex;
			justify-content: center;
			padding: 0 30px 0 30px;
			z-index: 1000;

		@include respond-to('large-up') {
				height: 150px;
		}

		@include respond-to('xlarge-up') {
				height: 150px;
		}
	}

	.main_nav {

		display: flex;
		justify-content: space-between;

		@include respond-to('xlarge-up') {
			max-width: 1170px;
			margin: auto;
		}

		&__container {
			padding-left: 40px;
			display: none;

			@include respond-to('large-up') {
				display: block;
			}
		}

		&__toggle {
			display: none;
		}

		&__title {
			display: flex;
			align-items: center;
		}

		&__list {
			margin: 0;
			padding: .5em 1em;
			background: black;
		}

		&__link {
			padding: .5em 1em;
			color: #333;
			text-decoration: none;

			&--active {
				background: white;
				color: black;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}

			&:hover {
				background: #CCC;
				color: black;
			}
		}

		&__logo {
			width: 160px;
			transition: ease .2s all;

			@include respond-to('medium-up') {
				width: 160px;
			}

			@include respond-to('large-up') {
				width: 160px;
			}

			@include respond-to('xlarge-up') {
				width: 200px;
			}
		}

		&__item {
			display: inline-block;
			margin-top: .5em;
		}

		&__menu {
			display: flex;
			align-items: center;

			@include respond-to('large-up') {
				display: none;
			}
		}

		&__link {
			display: inline-block;
			height: 60px;
			line-height: 68px;
			font-size: 16px;
			background-color: transparent !important;
			border-bottom: 4px solid transparent;
			font-family: "Helvetica", "Arial", sans-serif;
			padding: 0 15px;
			text-transform: uppercase;
			color: #757575;
			white-space: nowrap;

			@include respond-to('medium-up') {
				padding: 16px 8px;
			}

			@include respond-to('large-up') {
				font-size: 13px;
				padding: 16px 10px;
				color: #DFDFDF;
			}

			@include respond-to('xlarge-up') {
				font-size: 14px;
				padding: 0 14px;
			}

			&:hover {
				color: #DFDFDF;
				border-bottom: 4px solid #aec8d5;
			}
		}
	}

	.app-drawer {
		border-right: none;
		right: 0;
		left: auto;
		width: 240px;
		-webkit-transform: translateX(240px);
		transform: translateX(240px);

		.is-visible {
			-webkit-transform: translateX(0);
			transform: translateX(0);
		}

		.app-drawer-separator {
			height: 1px;
			background-color: #dcdcdc;
			margin: 8px 0;
		}

		.mdl-navigation__link.mdl-navigation__link {
			font-size: 16px;
			color: #757575;
			font-family: $font-heading;
		}

		span.mdl-navigation__link.mdl-navigation__link {
			color: #8bc34a;
		}

		.mdl-layout-title {
			position: relative;
			background: #6ab344;
			height: 160px;
		}

		.app-logo-image {
			position: absolute;
			bottom: 16px;
		}
	}
}


.mdl-layout--fixed-header {
    header {
        position: fixed;
    }
}

.app-drawer.is-visible {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.app-drawer-separator {
    height: 1px;
    background-color: #dcdcdc;
    margin: 8px 0;
  }

  .app-drawer .mdl-navigation__link.mdl-navigation__link {
    font-size: 16px;
    color: #757575;
    font-family: 'Brandon Grotesque Bold';
  }

  .app-drawer span.mdl-navigation__link.mdl-navigation__link {
    color: #8bc34a;
  }

  .app-drawer .mdl-layout-title {
    position: relative;
    background: #6ab344;
    height: 160px;
  }

	.app-drawer .app-logo-image {
	  position: absolute;
	  bottom: 16px;
	}
