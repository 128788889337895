.about {
	margin-top: 100px;
	margin-bottom: 150px;

	@include respond-to('medium-up') {
		margin-top: 100px;
	}

	@include respond-to('large-up') {
		margin-top: 150px;
	}

	@include respond-to('xlarge-up') {
		margin-top: 150px;
	}

	h5 {
		padding: 0 !important;
	}

	.section__title {
		padding: 10px 0;
	}

	.button {
		width: 100%;
	}

	&__form {

		.mdl-textfield, .mdl-checkbox {
			margin-bottom: 10px;
		}
	}

	&__container {
		background: $c-transparent;
		padding: 4px 14px 14px 14px;
	}

	.section__block--right {
		background-color: #b1b8d5;
		background: url('/assets/images/hero-Image_home_sml.jpg') center 30% no-repeat;
		background-size: cover;
		color: white;

		h3 {
			margin: 0 0 5px 0;
			padding: 0;
		}

		.section__title--underline {

			&:after{
			    border-bottom: 0.1em solid #fff !important;
			}
		}

	}
}

.form-success {
	background: $c-success;
	color: #fff;
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	font-size: 16px;

	.icon__wrapper {

		i {
			font-size: 22px;
			margin-top: 3px;
    		margin-left: 8px;
		}
	}
}


.card-logos img {
	width: 65px;
}
