// -------------------------------------------------------------
//
//	Zon's welcome message
//
//	------------------------------------------------------------

@import '../typography';
@import '../breakpoints';

.welcome {

	&__wrapper {
		min-height: 1vh;
		padding: 20px;
	}


	&__message {
		display: block;
		padding-left: 0.75em;
		margin-bottom: 1em;
	}

	&__header {
		font-family: $font_heading;
		font-size: 42px;
		margin: 24px 0;
		text-align: center;

		@include respond-to('medium-up') {
			font-size: 50px;
		}
	}

	&__version {
		font-size: 12px;
		font-weight: bold;
		text-align: center;
		font-family: $font_heading;
	}
}

.welcome__subheader {
	line-height: 180%;
    font-size: 100%;
    font-weight: normal;
	margin-bottom: 10px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 10px;
	text-align: center;
}
