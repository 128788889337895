.section {

    &__sidebar {

        &--panel {
            margin-bottom: 18px;
            background-color: $c-dull;
            padding: 20px;
            &:first-child {
                margin-bottom: 0;
            }

            @include respond-to('medium-up') {
                margin-bottom: 18px;
            }

            p {
                font-size: 100%;
            }
        }
    }

    &--icon {
      background: $c-primary;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 22px;

        i {
            font-size: 20px;
        }
    }

    &__container {

        @include respond-to('large-up') {
            display: flex;
        }
    }

	&__title {
		font-family: $font-heading;
		text-transform: uppercase;
    margin: 0 10px;

		&--underline {
			position: relative;

			&:after{
			    content: ' ';
			    position: absolute;
			    width: 2.5em;
			    border-bottom: 0.1em solid #000;
			    left: 0;
			    bottom: 0;
			}
		}
	}

    &__block {
        padding: 20px;
        margin-bottom: -8px;

        &--left {
            width: 100%;
            background-color: $c-dull;

            @include respond-to('large-up') {
                width: percentage(2/3);
            }
        }

        &--right {

            padding: 14px !important;
            width: 100%;

            @include respond-to('large-up') {
                width: percentage(1/3);
            }
        }
    }
}

.divider {
    border-top: 5px solid #30323a;
    margin: 1em auto;
    padding: 0;
    width: 20%;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.map-heading {
    font-size: 16px;
    padding: 0 8px;
    margin-bottom: 8px;
    font-weight: bold;
}

.map-detail {
    padding: 0 8px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: bold;

    span:last-child() {
        float: right;
        font-weight: 400;
    }
}

.map-detail:nth-child(odd) {
    background: #eee;
}

.contact {

    &__link {
        color: $c-link;
        text-decoration: underline;
    }
}

.search-retail,
.home-retail {
    background-image: url(../../assets/images/home_retail_space.jpg);
}
