// -------------------------------------------------------------
//
//	Search Bar Component
//
//	------------------------------------------------------------
//
//

.dull-back {
  background-color: $c-dull;
}

.search {
  background-color: #b1b8d5;
  background: url("/assets/images/hero-Image_home_sml.jpg") center 30% no-repeat;
  background-size: cover;
  background-position: left;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 100px;

  @include respond-to("medium-up") {
    height: 300px;
    margin-top: 100px;
  }

  @include respond-to("large-up") {
    height: 30em;
    margin-top: 150px;
  }

  @include respond-to("xlarge-up") {
    margin-top: 150px;
  }

  &__container {
    border-radius: 2px;
    padding: 20px !important;
    display: flex;
  }

  &__box {
    width: auto;
    margin: 20px !important;
    display: inline-block;
    padding: 10px;
    width: 80%;
    width: calc(100% - 40px);
    background-color: rgba(48, 50, 58, 0.7);

    @include respond-to("medium-up") {
      justify-content: space-evenly;
      display: flex;
      padding: 20px !important;
      width: 70%;
      border-radius: 2px;
    }
  }

  .button {
    width: 100%;
  }
}
