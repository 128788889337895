// -------------------------------------------------------------
//
//	Loading Component
//
//	------------------------------------------------------------


.loading {

    &__container {
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 160px;
    }
}
