.share_modal {

    &__header {
        border-bottom: 1px solid #ccc;
        text-align: center;
        padding-bottom: 16px;
    }

    &__heading {
        margin-bottom: 14px !important;
    }

    &__form-wrapper {
        padding: 1.5rem 0 0 0;
        justify-content:space-between;
        position: relative;

        .form-success {
            position: absolute;
            bottom: -20px;
            z-index: 1000;
            margin-top: 20px;
        }
    }

    &__button {
        width: 100%;
        height: 36px;
        position: relative;
        margin-top: 18px;
    }

    .button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        width: 30%;
    }

    &__wrapper {
        display: flex;
        justify-content: space-around;
    }

    .sml_button {
        width: 25%;
        margin: auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &__field {
        width: 45%;

        label {
            display: block;
            font-family: $font-heading;
            text-align: center;
            margin-bottom: 8px;
        }

        input {
            width: 100%;
            border: 1px solid #ccc;
            background: #fff !important;
        }

    }

    &__description {
        width: 90%;
        margin: auto;
    }
}

.social_share__container {
    padding: 2rem;
}

.social_share__header {
    text-align: center;
}


.Modal-overlay-0-1-1 {
    height: 100vh !important;
    z-index: 9999;
}

.Modal-modal-0-1-3 {
    border-radius: 5px;
    width: 550px;
    padding: 1.2rem !important;
}

.fullscreen_progress {
    background: rgba(0,0,0, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &__image {
        width: 100%;
        @include respond-to('medium-up') {
            width: 80%;
        }
    }
    &__close {
        color: #fff;
        position: absolute;
        top: 16px;
        right: 32px;
        width: 40px;
        height: 40px;
        border: 2px solid #fff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        i {
            font-size: 32px !important;
        }
    }
}
