
@media print{

    body.dev-mode::before{
      content:" " !important;
    }

    // .section--right {
    //     page-break-before: always;
    // }

    .tenancy__info-wrapper {
        display: block !important;
    }

    .tenancy {
      margin-top: 50px !important;
    }

    .section---left {
        width: 100% !important;
    }

    .app__wrapper {

      .dev-mode {
          display: none !important;
      }

      a[href]:after {
          content: none !important;
      }

        .header {
          display: none;
          margin-top: 30px;
          margin-top: 20px;

            .app-logo-image {
                display: none;
                -webkit-print-color-adjust: exact;
                background: #2E3339 !important;
                padding: 20px;
                width: 120px !important;
            }

            .main_nav__container {
                display: none !important;
            }
        }
    }

    .contact_main {
        display: none;
    }

    .content-block {

        button {
            display: none;
        }

        .tenancy__top {
            margin-left: 10px;
            margin-right: 10px;

            .mdl-cell:last-child {

                h3 {
                    display: none;
                }
            }
        }

        .tenancy__buttons {
            display: none;
        }

        .tenancy__map {
            width: 100%;
            height: 320px;
            border: 0;
            margin-bottom: 20px;
            display: none;
            page-break-before: always;
        }

        .video {
          display: none !important;
        }

        .video__title {
          display: none !important;
        }

        .image-gallery-image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 370px;

            img {
                width: 105%;
            }
        }

        .tenancy__share {
          display: none;
        }

        .section--icon {
            display: none;
        }

        .tenancy__enquiry {
            display: none;
        }

        .section--right {
          .section__sidebar--panel {
              &:first-child {
                  display: none !important;
              }
          }
        }


        .section--right {
          width: 100% !important;
            page-break-before: always;
        }
    }

    footer {
        display: none;
    }
}
