.tenancy {
  width: 100%;
  margin-top: 100px;

  @include respond-to("medium-up") {
    margin-top: 100px;
    padding-top: 30px;
  }

  @include respond-to("large-up") {
    margin-top: 150px;
  }

  @include respond-to("xlarge-up") {
    margin-top: 150px;
  }

  &__heading {
    margin: 0;
    font-family: $font-heading;
  }

  .section__title {
    margin: 20px 0;
  }

  &__info {
    display: flex;
    position: relative;
    margin-bottom: 18px;
    align-items: center;
  }

  &__detail {
    margin-bottom: 0;
    font-weight: 600;
    color: #444;

    &--title {
      font-weight: 400 !important;
    }

    &--item {
      font-size: 14px !important;
      margin-bottom: 12px;
      display: flex;

      i {
        font-size: 18px;
        opacity: 0.7;
        margin-top: 4px;
      }

      @include respond-to("medium-up") {
        font-size: 14px !important;
        margin-bottom: 4px;
      }
    }

    &--name {
      font-size: 18px !important;
      margin-bottom: 16px;

      @include respond-to("medium-up") {
        margin-bottom: 10px;
      }
    }

    &--heading {
      line-height: 1.5;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
    }

    &--suburb {
      font-weight: 300;
      font-size: 16px;
    }

    span:first-child {
      // width: 15%;
      display: inline-block;
    }
  }

  &__video {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 26px;

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__type {
    font-family: $font-heading;
    text-transform: uppercase;
    font-size: 12px;
    display: inline;
    background: $c-primary;
    padding: 5px 12px;
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1.5;
    display: none;

    @include respond-to("medium-up") {
      display: block;
    }
  }

  &__feature {
    margin-top: 20px;

    &--row {
      display: flex;
      border-bottom: 1px solid #dae1e5;
    }

    &--item {
      margin-bottom: 0;
      padding: 5px 12px;
      color: #444;

      &:first-child {
        width: 40%;
        font-size: 14px;

        @include respond-to("medium-up") {
          width: 25%;
        }
      }

      &:last-child {
        font-weight: bold;
      }
    }

    &--netrent {
      &:first-child {
        width: 25%;
      }

      &:last-child {
        width: 75%;
        text-align: right;
        font-size: 16px;
      }
    }
  }

  &--fineprint {
    font-weight: 300;
    font-size: 11px;
  }

  &__description {
    margin-top: 26px;
    margin-bottom: 26px;
    color: #444;
    font-size: 14px;
    line-height: 24px;
  }

  &__top {
    padding: 8px;
  }

  &__share {
    rect {
      fill: none;
    }

    .social-icon {
      path {
        fill: #444;
      }
    }

    &--header {
      text-transform: uppercase;
      text-align: center;
      color: #444;
    }
  }

  .share_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 65%;
    margin: auto;
  }

  .section--right {
    span:first-child {
      display: inline-block;
      font-weight: bold;
    }

    span:last-child {
      //width: 55%;
    }
  }

  &__map {
    width: 100%;
    height: 320px;
    border: 1px solid #aaa;
  }

  &__item {
    margin-bottom: 0;
  }

  &__info-wrapper {
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .form__input {
    background: transparent !important;
    border-radius: 0;
    font-size: 14px;
    height: 42px;
    padding-left: 0 !important;
  }

  .bar {
    width: 100% !important;
  }

  &__contact-block {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    // width: 40%;
  }

  &__contact {
    //display: flex;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  .button {
    width: 150px;
    float: right;
  }

  .sml_button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    color: #444;
    margin-bottom: 20px;
    display: none;

    @include respond-to("medium-up") {
      display: block;
      float: right;
    }

    i {
      margin-right: 5px;
      font-size: 120%;
    }
  }

  .lrg_button {
    width: 100%;
  }

  .share_border {
    border-top: 1px solid #dae1e5;
    border-bottom: 1px solid #dae1e5;
  }
}

.image-gallery-thumbnail {
  width: 100px;
  height: 55px;
  overflow: hidden;

  @include respond-to("large-up") {
    height: 75px;
  }
}

.SocialMediaShareButton,
.EmailShareButton {
  opacity: 0.6;
  cursor: pointer;
  transition: ease 0.2s all;
  outline: none;

  &:hover {
    opacity: 0.9;
    transition: ease 0.2s all;
  }
}

.contact_main {
  background-color: #aec8d5;

  h4 {
    margin: 0;
    text-align: center;
    font-family: $font-heading;
    font-size: 16px;
    padding: 4px 0;
  }
}

.livefeed {
  margin-bottom: 32px;
  img {
    width: 100%;
  }
}
