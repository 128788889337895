.image-gallery-image {
    height: 255px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-to('medium-up') {
        height: 450px;
    }

    @include respond-to('large-up') {
        height: 580px;
    }

    img {
        max-width: 100%;
        width: inherit !important;
    }
}

.fullscreen {
    .image-gallery-image {
        height: 90vh;
        max-height: 90vh;
        img {
            max-width: 80% !important;
        }
    }
}

.image-gallery-thumbnail {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
}



.image-gallery {
    .image-gallery-fullscreen-button {
        background-color: rgba(0,0,0,.5);
        padding: 0;
        &::before {
            padding: 8px;
        }
    }
}
