@import "../typography";

.listings {

    i {
        font-size: 110%;
        margin-top: -2px;
    }

    &__icon {
        font-size: 24px !important;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        margin-top: 20px;
        padding-bottom: 30px;

        @include respond-to('medium-up') {
            grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to('large-up') {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__image {
        max-height: 110%;
        width: 100%;
    }

    &__results {
        margin-top: 20px;
    }

    &__info {
        font-size: 12px;
        margin-bottom: 0;
        color: #444;
        font-weight: bold;
        display: flex;

        .property_name {
            width: 40%;
            font-weight: bold;
        }

        span {
            display: block;
            font-weight: 400;
            width: 55%;
            span {
                display: inline;
            }
        }
    }

    &__info-wrapper {
        margin-bottom: 20px;
        padding: 0 16px;
    }

    &__button {
        background: #333;
        color: #fff;
        font-weight: 400;
        font-size: 80%;
        width: calc(100% - 52px);

        &:hover {
            background: #333 !important;
        }
    }

    &__hover {
        width: 100%;
        background: rgba(0,0,0,.7);
        height: 240px;
        position: absolute;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: ease .2s all;

        p {
            padding: 8px 14px;
            font-family: $font-heading;
            border: 1px solid #fff;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__property {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        &--title {
            font-size: 18px;
            padding-right: 8px;
        }

        &--type {
            background: $c-primary;
            font-family: $font-heading;
            text-transform: uppercase;
            padding: 0 8px;
            border-radius: 4px;
            font-size: 9px;
        }

        &--location {
            font-size: 14px;
            font-weight: 200;
        }
    }

    &__property-details {
        border-top: 1px solid #ddd;
        margin-top: 8px;
        padding-top: 8px;
    }

    &__note {
        color: #fff;
        font-weight: 100;
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
         i {
             font-size: 22px !important;
             margin-right: 8px;
         }
    }
}

.highlight_listing {
    background: #4c5f7f !important;
    height: 393px;
    width: 340px !important;
    cursor: pointer;

    .search_result__image {
        background: #4a5b79 !important;
    }

    .listings__property--title {
        color: #fff;
    }

    .listings__property--location {
        color: #fff;
    }
}

.search_result {
    background: $c-dull;
    position: relative;
    border-radius: 4px;
    width: 95%;
    max-width: 340px;
    margin-bottom: 40px;
    transition: ease .2s all;

    &:hover {
        filter: brightness(1.03);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                    0 3px 1px -2px rgba(0, 0, 0, 0.2),
                    0 1px 5px 0 rgba(0, 0, 0, 0.12);
        transition: ease .2s all;

        .listings__hover {
            opacity: 1;
            transition: ease .2s all;
        }
    }

    &__image {
        background: #e6eef1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        height: 240px;
        overflow: hidden;

    }

    i {
        font-size: 110%;
        margin-top: -3px;
        margin-left: 3px;
    }
}

.sub_filters {

    @include respond-to('large-up') {
     //   display: flex;
	 //   justify-content: flex-end;
	 text-align: right;
    }

    .button {
        margin-left: 10px;
        margin-bottom: 10px;

        i {
            font-size: 110%;
            margin-top: -3px;
            margin-right: 3px;
        }
    }
}

.warehouses {

    .search {
        height: 15em;
        background-image: url("/assets/images/home_warehouse.jpg") !important;
    }
}

.offices {

    .search {
        height: 15em;
        background-image: url("/assets/images/home_office.jpg") !important;
    }
}

.retail-show-rooms {

    .search {
        height: 15em;
        background-image: url("/assets/images/home_retail_space.jpg") !important;
    }
}

.new-developments {

    .search {
        height: 15em;
        background-image: url('/assets/images/home_developments.jpg') !important;
    }
}

.residential {

    .search {
        height: 15em;
        background-image: url("/assets/images/home_residential.jpg") !important;
    }
}

.auckland-properties-for-sale {

    .search {
        height: 15em;
        background-image: url("/assets/images/home_for_sale.jpg") !important;
    }
}

.auckland-land-yards-for-lease-and-sale {

    .search {
        height: 15em;
        background-image: url("/assets/images/home_land_yard.jpg") !important;
    }
}

.search-result_image_missing {
    width: 70%;
    background: #2E3339;
    padding: 50%;
}

.map-info-wrapper {

    .listings__button {
        position: static !important;
        margin-top: 12px;
        padding: 0 !important;
        width: 100%;
    }
}

.map-image {
    margin: 10px 0;
    img {
        max-height: 140px;
    }

    .search-result_image_missing {
        display: none;
    }
}

.map-wrapper {
    padding: 5px;
    background-color: $c-dull;
}

.search-results {
    .search {
        height: 15em;
    }
}
