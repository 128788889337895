.home {
  &__category {
    background-size: cover;
    background-repeat: none;
    background-color: #b1b8d5;
    background-position: 50%;
    border-radius: 0;
    text-align: center;
    height: auto;
    min-height: 0;
  }

  &-image_container {
	display: flex;
	gap: 25px;
	@media screen and (min-width: 600px) {
		flex-direction: row !important;
		gap: 50px;
	}
	justify-content: center;
	:first-child {
		img {
			height: 125.5px;
			width: 325px;
		}
	}
	img {
		height: 125.5px;
	}
  }

  &__link {
    width: 66%;
    height: auto;
    margin: 30px auto;
    color: #fff;
    text-decoration: none;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 2px;
    display: block;
    font-family: $font-heading;
    text-transform: uppercase;
    background: $c-transparent;
  }
}

.home_section {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;

  &__subheading {
    font-family: $font-heading;
    text-align: center;
    margin-bottom: 30px;
    font-size: 26px;
  }
}

.featured_section {
  border-bottom: 1px solid #ddd;
}

.home__category img {
  display: block;
  margin: auto;
  max-height: 90px;
  max-height: 70px;
  margin-bottom: 10px;
}

.home-developments img {
  padding-left: 24px;
}

.home-for_sale {
  background-image: url("../../../src/assets/images/home_for_sale.jpg") !important;
}

.search-office,
.home-office {
  background-image: url("/assets/images/home_office.jpg") !important;
}

.search-residential,
.home-residential {
  background-image: url("/assets/images/home_residential.jpg") !important;
}

.search-retail,
.home-retail {
  background-image: url("../../../src/assets/images/home_retail_space.jpg") !important;
}

.home-retail img {
  padding-right: 20px;
}

.home-warehouse {
  background-image: url("/assets/images/home_warehouse.jpg") !important;
}

.search-land_yard,
.home-land-yard {
  background-image: url("/assets/images/home_land_yard.jpg") !important;
}

.search-developments,
.home-developments {
  background-image: url("/assets/images/home_developments.jpg") !important;
}

.member_section {
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ddd;
  padding-top: 36px;
  a {
    display: flex;
    justify-content: center;
  }
  img {
    max-width: 300px;
  }
}

.nzgbc__link {
	margin-bottom: 50px;
}
